import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import React from 'react';
import { useState } from 'react';
import { CircularProgress } from "@mui/material";

import awsmobile from '../src/aws-export';

const apiUrl = awsmobile.aws_backend_api_url;

const required = (value) => {
  if (value) return undefined;
  return 'Required';
}
const minLength = (value) => {
  if (value && value.length >= 8) return undefined;
  return 'Password must be at least 8 characters';
}
const hasLowercase = (value) => {
  if (value && /[a-z]/.test(value)) return undefined;
  return 'Password must contain at least one lowercase letter';
}
const hasUppercase = (value) => {
  if (value && /[A-Z]/.test(value)) return undefined;
  return 'Password must contain at least one uppercase letter';
}
const hasNumber = (value) => {
  if (value && /[0-9]/.test(value)) return undefined;
  return 'Password must contain at least one number';
}
const hasSpecial = (value) => {
  if (value && /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)) return undefined;
  return 'Password must contain at least one special character';
}
const validatePassword = (value) => {
  if (required(value)) return required(value);
  if (minLength(value)) return minLength(value);
  if (hasLowercase(value)) return hasLowercase(value);
  if (hasUppercase(value)) return hasUppercase(value);
  if (hasNumber(value)) return hasNumber(value);
  if (hasSpecial(value)) return hasSpecial(value);
  return undefined;
}

function App() {

  const searchParams = new URLSearchParams(window.location.search)

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [helper1, setHelper1] = useState(null);
  const [helper2, setHelper2] = useState(null);

  const matchPassword = (value) => {
    if (!value || value.length === 0) {
      return undefined;
    }
    if (value && value === password) return undefined;
    return 'Passwords do not match';
  }

  const uid = searchParams.get('uid');
  const email = searchParams.get('email');

  const CheckResult = ({ data }) => {

    if (data.message === 'success')
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={awsmobile.logo_img_url} alt="Exponent" style={{ width: "100%" }} />
          <Typography component="h1" variant="h5">
            Your account is now active.
          </Typography><br />
        </Box></Container>)

        return (
          <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={awsmobile.logo_img_url} alt="Exponent" style={{ width: "100%" }} />
            <Typography component="h1" variant="h5">
              There is something wrong. Please contact the helpdesk.
            </Typography><br />
            </Box>
            </Container>
        )
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      setLoading(true);
      // get the data from the api

      const params = {
        password: data.get("password"),
        uid: data.get("uid"),
        email: data.get("email"),
      }

      const options = {
        method: 'POST',
        body: JSON.stringify(params),
      };

      const response = await fetch(`${apiUrl}/user/active`, options);

      // convert the data to json
      const json = await response.json();
      // set state with the result
      setData(json);

    } catch (error) {
      console.log('error', error);
    }
    finally {
      setLoading(false);
    }
  };

  if (!uid || !email) return <p>Please check the active link again.</p>
  if (!data)
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={awsmobile.logo_img_url} alt="Exponent" style={{ width: "100%" }} />

          <Typography component="h1" variant="h5">
            Create a password to activate your account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              type="password"
              name="password"
              helperText={helper1}
              onChange={(event) => {
                const value = event.target.value;
                setHelper1(validatePassword(value));
                setPassword(value);
              }}
              autoFocus
              disabled={isLoading}
            />
            <div style={{ display: "none" }}>
              <TextField
                name="uid"
                id="uid"
                value={uid}
                hidden={true}
              />
              <TextField
                name="email"
                id="email"
                value={email}
                hidden={true}
              />
            </div>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Repeat Password"
              type="password"
              id="password2"
              helperText={helper2}
              onChange={(event) => {
                const value = event.target.value;
                setHelper2(matchPassword(value));
              }}
              disabled={isLoading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              {isLoading ? <CircularProgress /> : "Confirm"}
            </Button>
          </Box>
        </Box>
      </Container>
    );

  console.log("data", data);
  return <CheckResult data={data} />
}

export default App;
